export enum MenuButtonsEnum {
    ACCESSES = 'Moje dostępy',
    LEARNING_MATERIALS = 'Materiały dydaktyczne',
    STUDENT_ZONE = 'Strefa ucznia',
    LS = 'Lepsza szkoła',
    EVENTS = 'Wydarzenia',
    ORDERS = 'Zakupy',
    PROFILE = 'Mój profil',
    PORTAL = 'Portal GWO',
}

export enum AddEventsToListDirection {
    FRONT = 'front',
    BACK = 'back'
}

export enum MenuButtonPlaceEnum {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export enum ProductsTypeTitleEnum {
    MHB_MEC = 'Multipodręczniki / Multićwiczenia',
    MHB = 'Multipodręczniki',
    APP = 'Aplikacje',
    COMPOSE = 'Kompozytor',
    DIARIES = 'e-GWO dzienniki',
    VIDEO = 'Wykłady online',
    EBOOK = 'e-Booki',
    HOMEWORK = 'Klasówki i prace samodzielne',
}

export enum ProductsTypeUrlEnum {
    MHB = 'mhb',
    APP = 'app',
    ANNOUNCE = 'announcement',
    VIDEO = 'video',
    EBOOK = 'ebook',
    HOMEWORK = 'app/homework',
    APP_CODES = 'take_codes',
    DIARIES = 'app/register',
    LOKO_CODES = 'loko',
    DEMOS = 'demos',
    LM = 'learning_materials',
}

export enum OrdersTypeTitleEnum {
    BOOKSHOP = 'Księgarnia detaliczna',
    GRANT = 'Księgarnia dotacyjna',
    LS = 'Lepsza Szkoła',
    PACKET = 'Pakiety szkolne',
    ENLARGE = 'Księgarnia powiększamy',
    ACADEMY = 'Akademia GWO',
}

export enum OrdersTypeChanelEnum {
    BOOKSHOP = 'retail',
    GRANT = 'grant',
    LS = 'ls',
    PACKET = 'packet',
    ENLARGE = 'enlarge',
    ACADEMY = 'academy',
    EMPIK = 'empik',
}

export enum OrderStatusIdEnum {
    PLACED = 1,
    PROCESSING = 2,
    CANCELLED = 3,
    SENT = 4,
}

export enum OrderStatusModelEnum {
    PLACED = 'Złożone',
    PROCESSING = 'W trakcie realizacji',
    CANCELLED = 'Anulowane',
    SENT = 'Wysłane',
}

export enum EventActionTypeEnum {
    NORMAL = 0,
    SUCCESS = 1,
    CANCEL = 2
}

export enum EventSourceTypeEnum {
    INTERNAL = 'internal',
    FREE_COURSE = 'freeCourse',
    PAID_COURSE = 'paidCourse',
    CLOSED_COURSE = 'closedCourse',
    BETTER_SCHOOL = 'betterSchool',
    RETAIL = 'retail'
}

export enum UserFunctionsEnum {
    TEACHER = 'teacher',
    TEACHER_VERIFIED = 'teacher_verified',
    LEARNING_MATERIALS = 'learning_materials',
    PARENT = 'parent',
    STUDENT = 'student',
    QUASI = 'quasi',
    OTHER = 'other',
    DIRECTOR = 'director'
}
