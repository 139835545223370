export const GWO_FULL_NAME = 'Gdańskie Wydawnictwo Oświatowe';
export const DAYS_BEFORE_EXPIRED = 30;
export const NEWS_ROUTE_SLUG_PARAMETER = 'slug';
export const EVENT_ROUTE_DATE_FROM_PARAMETER = 'dateFrom';
export const EVENT_ROUTE_DATE_PARAMETER = 'date';
export const DIARIES_IMG_URL = 'assets/img/gwo-diaries.png';
export const GWO_PUBLISHER_ID = 1;
export const LOCOMOTIVE_CLUB_ID = 7;
export const GWO_TNS_PUBLISHER_ID = 55;
export const GWO_TNS_SCOPE = 18;
export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
export const NEWS_DETAILS_RESOLVED_OBJECT_NAME = 'newsDetails';

export const DATE_SEPARATOR = 'T';
export const GLOBAL_MESSAGES = {
    ERROR_TRY_LATER: 'Sprobuj później',
    ERROR_TITLE: 'Wystąpił błąd',
    ERROR_HTTP: 'Wystąpił błąd podczas pobierania danych',
    ERROR_AUTH: 'Błąd Autoryzacji',
    LOADING: 'Ładowanie danych',
    UPDATED: 'Zaktualizowano.',
    DELETED: 'Usunięto.',
    SAVED: 'Zapisane.',
    COPIED: 'Skopiowane do schowka',
    UPDATE_MEMBERSHIP: 'Przedłuż członkostwo',
    SESSION_EXPIRED: 'Sesja wygasła.',
}

export const BUTTON_TEXT = {
    UPDATE: 'Aktualizuj',
    CLOSE: 'Anuluj',
    DELETE: 'Usuń',
    SAVE: 'Zapisz',
    ADD: 'Dodaj',
    OK: 'OK',
}

export const PRODUCT_STATUS_TEXT = {
    BUY_AGAIN: 'Kup ponownie',
    STATUS_NEW: 'nierozpoczęty',
    STATUS_OPENED: 'rozpoczęty',
    STATUS_FINISHED: 'zakończony',
    DEMO_VERSION: 'Wersja demo. Wypróbuj darmowy dostęp',
    ACCESS_EXPIRED_IN: 'Dostęp kończy się za ',
    GO_TO_PREMIUM: 'Przejdź na wersję premium',
    PREMIUM: 'Wersja premium',
    PREMIUM_NEW: 'Wersja premium – rozpocznij',
}

export const PRODUCT_ACCESS_TYPE_TEXT = {
    TEACHER: 'Dostęp nauczycielski',
    STUDENT: 'Dostęp uczniowski',
    ASSIGNED: 'Dostęp przydzielony',
}

export const PRODUCT_MODIFY_CSS_CLASS = {
    DISABLED: ' --disabled',
    EXPIRED: ' --expired',
    TRANSPARENT: ' --transparent',
    DEMO: ' --demo',
}

export const LM_ERROR_MESSAGE = {
    BLANK: 'Brak dostępnych materiałów.',
}

export const ORDER_ERROR_MESSAGE = {
    BLANK: 'Brak zamówień',
}

export const CLUB_FORM_MESSAGES = {
    LOADING: 'Wczytywanie listy klubów...',
    NO_DATA: 'Obecnie nie należysz do żadnego klubu GWO.',
}

export const SPINNER_COLOR = '#5C5EB2';
export const SPINNER_BACKGROUND_COLOR = '#FFFFFF';
export const CALENDAR_SPECIAL_DATE_CLASSNAME = 'special-date';
export const PAST_CALENDAR_SPECIAL_DATE_CLASSNAME = 'past-special-date';
export const ASSIGNED_CALENDAR_SPECIAL_DATE_CLASSNAME = 'assigned-special-date';
export const TRANSPARENT_ORDINARY_CALENDAR_DATE = 'transparent';

export const AVALIABLE_FOR_USER_FUNCTION_PARAMETER = 'userFunctions';

export const CALENDAR_MONTH_VIEW_TYPE = 'month';

export const RODO_INFO_EMAIL = {
    GWO: 'dane.osobowe@gwo.pl',
    IRE: 'dane.osobowe@ire.edu.pl',
}

export const PASSWORD_LABELS = {
    CURRENT: 'Wpisz obecnie używane hasło',
    NEW: 'Wpisz nowe hasło',
    NEW_REPEAT: 'Powtórz nowe hasło',
}

export const PASSWORD_LABELS_VALIDATION = {
    PASSWORD_PATTERN: 'Nowe hasło musi się różnić od poprzedniego oraz składać z min. 8 znaków, w tym liter i cyfr.',
    CURRENT_PASS: 'Podane nowe hasło jest takie samo jak obecne.',
    NOT_SAME_PASS: 'Nowe hasła różnią się.',
}

export const HOMEWORK_TEXT = {
    SOLVED: 'Wszystkie zadania rozwiązane',
    NOT_SOLVED: 'Zostały zadania do rozwiązania',
}

export const POPUP_OPEN_ERROR = 'Twoja przeglądarka zablokowała otwieranie nowej karty. Zezwól na tę czynność.';
